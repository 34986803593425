import * as Vue from "vue";
import "./assets/styles/global.sass";
import * as VueRouter from "vue-router";
import routes from "./routes";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createHead } from "@vueuse/head";
import App from "./App.vue";

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHistory(),
  routes: routes, // short for `routes: routes`
});

const app = Vue.createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
const head = createHead();
app.use(head);
app.mount("#app");
