const routes = [
  {
    path: "/",
    component: () => import("@/views/HomeView"),
  },
  {
    path: "/:slug",
    component: () => import("@/views/PageView"),
  },
  {
    path: "/:locale/:slug",
    component: () => import("@/views/PageView"),
  },
  {
    path: "/channel/:slug",
    component: () => import("@/views/ChannelView"),
  },
  {
    path: "/:locale/channel/:slug",
    component: () => import("@/views/ChannelView"),
  },
  {
    path: "/not-found",
    component: () => import("@/views/NotFoundView"),
  },
];
export default routes;
